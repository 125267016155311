<template>
  <v-container fluid>
    <v-card elevation="0" outlined>
      <v-card-title class="headline">
        {{ $t("settings.lang_editAPIKey") }}
      </v-card-title>

      <v-divider class="pa-0 ma-0" />

      <v-card-text>
        <v-row
          align="center"
          align-content="center"
          justify="center"
          no-gutters
        >
          <v-col cols="12" md="6" sm="10">
            <v-text-field
              v-model="data.alias"
              @focus="showTouchKeyboard"
              :data-layout="KEYBOARD.KEYSETS.NORMAL"
              :label="$t('generic.lang_alias')"
              autocomplete="off"
              outlined
              required
            ></v-text-field>

            <v-text-field
              v-if="data.apiToken"
              v-model="data.apiToken"
              :label="$t('generic.lang_apiToken')"
              autocomplete="off"
              outlined
              disabled
              readonly
            ></v-text-field>

            <v-text-field
              v-if="data.apiKey"
              v-model="data.apiKey"
              :label="$t('generic.lang_apiKey')"
              autocomplete="off"
              outlined
              disabled
              readonly
            ></v-text-field>

            <v-btn
              @click="save"
              :loading="loading"
              :disabled="data && data.alias.length < 1"
              class="ma-0"
              color="primary"
              block
              large
            >
              {{ $t("generic.lang_save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        id="onScreenKeyboard"
        class="internalWidth"
        :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet"
        v-if="touchKeyboard.visible"
        :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard"
        :accept="hideTouchKeyboard"
        :input="touchKeyboard.input"
      />
    </div>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "../../../config";
import { Events } from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";

export default {
  name: "EditAPIKeyComponent",

  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      loading: false,
      data: "",
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      self.loading = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.MODULESETTINGS.CALLCENTER.PROVIDERS.APIKEYS.GET,
          {
            id: this.$route.params.id,
          }
        )
        .then((res) => {
          this.data = res.data.formfillData.textFields;
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          self.loading = false;
        });
    },
    save() {
      let self = this;
      self.loading = true;

      this.axios
        .post(
          ENDPOINTS.SETTINGS.MODULESETTINGS.CALLCENTER.PROVIDERS.APIKEYS.UPDATE,
          {
            id: this.data.id,
            alias: this.data.alias,
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_updatedSuccessfully"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || res.data.status,
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          self.loading = false;
        });
    },
  },
};
</script>
